import Login from './login/login';
import './css/App.css';

import 'bootstrap/dist/css/bootstrap.min.css';

function App() {

	return (
		<Login/>
	);
}

export default App;
