import React, { useEffect, useState } from 'react';

import { Button, Modal } from 'react-bootstrap';

import ConversationComponent from "../components/ConversationComponent";
import ContactListComponent from "../components/ContactListComponent";
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import { refreshTokenSetup } from './refreshToken';
import styled from "styled-components";
import Logo from '../img/mlLogo.png';

const Container = styled.div`
	display: flex;
	height: 100vh;
	width: 100%;
	flex-direction: row;
	align-items: center;
	background: #f8f9fb;
`;

const ChatPlaceholder = styled.img`
	width: 240px;
	height: 240px;
	border-radius: 50%;
	object-fit: contain;
`;

const Placeholder = styled.div`
	flex: 3;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: 14px;
	gap:10px;
	color: rgba(0, 0, 0, 0.45);
	span {
		font-size: 32px;
		color: #525252;
	}
`;

const clientId = "121914022648-aq7fehj4a03md8dt8qrfmqr3lnnvmhmh.apps.googleusercontent.com";

function MyVerticallyCenteredModal(props) {
    return (
        <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Mensaje de Alerta
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Error de Cuenta</h4>
                <p>
                    La cuenta con la que intento ingresar en el sistema de Soporte ISINET no existe o no tiene los privilegios para acceder a ella, se recomienda contactar al administrador para que pueda acceder al sistema de ISINET.
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide} variant="secondary">Cerrar</Button>
            </Modal.Footer>
        </Modal>
    );
};


function Login() {

    const [showlogoutButton, setShowlogoutButton] = useState(false);
    const [showloginButton, setShowloginButton] = useState(true);
    const [modalShow, setModalShow] = useState(false);
    const [showChat, setShowChat] = useState(false);
    const [content, setContent] = useState('');

    const onLoginSuccess = (res) => {
        if(res.profileObj.email === 'daniel@isinet.ws' || res.profileObj.email === 'daniel@isinet.mx' || res.profileObj.email === 'andrea@isinet.ws' || res.profileObj.email === 'andrea@isinet.mx' || res.profileObj.email === 'rbalassa@isinet.ws' || res.profileObj.email === 'rbalassa@isinet.mx'){
            setShowloginButton(false);
            setShowlogoutButton(true);
            setShowChat(true);
            refreshTokenSetup(res);
        } else {
            setModalShow(true);
            onLoginFailure();
        }
    };

    const onLoginFailure = (res) => {
        console.log('Login Failed:', res);
        console.clear();
    };

    const onSignoutSuccess = () => {
        console.clear();
        setShowloginButton(true);
        setShowlogoutButton(false);
    };

    useEffect(() => {
        fetch("https://www.google.com.mx", {
            headers: {
                "Authorization": "JWT eyJ0eXAiOiJK",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "POST, PUT, DELETE, GET, OPTIONS",
                "Access-Control-Request-Method": "*",
                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization"
            }
        })
        .then(response => response.text())
        .then(data => setContent(data))
        .catch(err => err)
    }, []);

    return (
        <div>
            { showloginButton ?
                <div className="App">
                    <header className="App-header">
                        <img src={Logo} style={{width: "15%"}} alt="logo"/>
                        <br/>
                        <GoogleLogin
                            clientId={clientId}
                            buttonText="ISINET Whats App Businees"
                            /*render={renderProps => (
                                <button onClick={renderProps.onClick} disabled={renderProps.disabled}>ISINET Whats App Businees</button>
                            )}*/
                            onSuccess={onLoginSuccess}
                            onFailure={onLoginFailure}
                            cookiePolicy={"single_host_origin"}
                            isSignedIn={true}
                        />
                    </header>
                </div>
            : null }

            { showlogoutButton ?
                <GoogleLogout
                    className="logoutSty"
                    clientId={clientId}
                    buttonText="Cerrar Sección ISINET Whats App Businees"
                    onLogoutSuccess={onSignoutSuccess}
                >
                </GoogleLogout>
            : null }

            { showChat ?
                <div style={{width: "100%", height: "100%"}}>
                    <iframe
                        id="inlineFrameExample"
                        title="Inline Frame Example"
                        width="100%"
                        height="100%"
                        src={content}
                    >
                    </iframe>
                </div>
			: null }

            <MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)}/>
        </div>
    );
};

export default Login;